<template>
    <b-modal v-if="isModalBot" id="transfer-conversation-dlg" title="Alihkan Pesan Ke Bot">
        <div class="modal-body modal-assign-text">
            <form>
                <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="selectedTypeBot" id="exampleRadios1" value="NO_REPLY" checked>
                    <label class="form-check-label" for="exampleRadios1">
                        Alihkan pesan ke bot
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="selectedTypeBot" id="exampleRadios2" value="REPLY">
                    <label class="form-check-label" for="exampleRadios2">
                        Alihkan pesan ke bot dan balas
                    </label>
                </div>
            </form>
        </div>
        <ErrorMessage v-bind:error="error" />
        <template v-slot:modal-footer>
            <div>
                <button type="button" class="btn btn-default m-r-10"
                    @click="$bvModal.hide('transfer-conversation-dlg')">Batal</button>
                <button type="button" @click="transferMessage" class="btn btn-primary tst3" :disabled="isLoadingNow">
                    <b-spinner v-if="isLoadingNow" small type="grow"></b-spinner>
                    Sekarang
                </button>
            </div>
        </template>
    </b-modal>
    <b-modal v-else id="transfer-conversation-dlg" title="Tangani Pesan Ini">
        <div class="modal-body modal-assign-text">Ketik pesan untuk rekan disini
            '{{ this.toOperatorName }}'?
            <div class="clearfix"></div>
            <textarea v-bind:text="noteToReceiverOp" placeholder="Ketik pesan disini.." class="form-control b-0"></textarea>
        </div>
        <ErrorMessage v-bind:error="error" />
        <template v-slot:modal-footer>
            <div>
                <button type="button" class="btn btn-default m-r-10"
                    @click="$bvModal.hide('transfer-conversation-dlg')">Batal</button>
                <button type="button" @click="transferMessage" class="btn btn-primary tst3" :disabled="isLoadingNow">
                    <b-spinner v-if="isLoadingNow" small type="grow"></b-spinner>
                    Sekarang
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import ErrorMessage from '../components/ErrorMessage'
    import Toast from '../plugins/vuenotification'
    var {
        updateAssignTo
    } = require('../utils/message-utils')

    export default {
        data: function () {
            return {
                selectedTypeBot: 'REPLY',
                noteToReceiverOp: '',
                isLoadingNow: false,
                error: {}
            }
        },
        methods: {            
            transferMessage: async function () {
                this.error = {}
                try {
                    this.isLoadingNow = true

                    const body = {
                        id: this.contactId,
                        operatorId: this.toOperatorId,
                        operatorName: this.toOperatorName,
                        sessionId: this.sessionId
                    }

                    if(this.toOperatorId == 'bot') {
                        body.selectedTypeBot = this.selectedTypeBot == 'NO_REPLY' ? 'NO_REPLY' : 'REPLY'
                    }

                    await updateAssignTo(body);

                    this.isLoadingNow = false
                    this.$bvModal.hide('transfer-conversation-dlg')
                    this.$emit('take-callback', true)
                } catch (error) {
                    this.isLoadingNow = false
                    if(error.response) {
                        Toast.showToast("error", error.response.data.message)
                    } else {
                        Toast.showToast("error", error.message)
                    }

                    // this.error = this.$helper.createError(error)
                }
            }
        },
        props: ['isModalBot', 'toOperatorName', 'toOperatorId', 'contactId', 'contactName', 'sessionId'],
        components: {
            ErrorMessage
        }
    }
</script>